
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../environments/environment";
import { Subject } from "rxjs";
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable()
export class PresService {
  public phl_id; // = localStorage.getItem("pid");
  public p_update_by; // = localStorage.getItem("p_update_by");
  public medicine_status = 1;
  public hideheadernavs = false;
  public getMasters;
  public selectedDate = {
    date: null,
    reformateddate: null
  };
  basepythonUrl = environment.basepythonUrl;
  baseUrl = environment.baseUrl;
  pdfUrl = environment.pdfUrl;
  medicine_list = environment.medicine_list;
  private dateUpdateEvent = new Subject<any>();
  rdata = {};
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.selectedDate = {
      date: new Date(),
      reformateddate: moment().format('YYYY-MM-DD')
    };
    this.phl_id = localStorage.getItem("pid");
    this.p_update_by = localStorage.getItem("p_update_by");
    //this.getMasters = this.http.get(this.baseUrl + "Prescription_advise/getPacking_masters", httpOptions);

  }

  public getdateListener() {
    return this.dateUpdateEvent.asObservable();
  }

  public updateDate(datepayload) {
    console.log(datepayload);
    this.dateUpdateEvent.next(datepayload);
  }
  getPresAdvicelist(date) {
    console.log(this.phl_id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    var body = {
      date: date, //this.selectedDate.reformateddate,
      limitstart: 0,
      limitend: 100000,
      pharmacy_id: this.phl_id
    };
    console.log('prescriptionadvicelist');
    console.log(body);

    return this.http.post(
      this.baseUrl + "Prescription_advise/getPrescription_advise",
      body
    );
    /* return this.http.post('https://www.reqres.in/api/users', {
        "name": "morpheus",
        "job": "leader"
    }, httpOptions); */
  }

  getPresAdvicedetails(vrid) {
    var body = {
      visit_report_id: vrid
    };
    var rdata = this.http.post(
      this.baseUrl + "Prescription_advise/getPrescription_advise_details",
      body
    );
    console.log(rdata);
    return rdata;
  }
  getmedicinelistdetails(phid) {
     var body = {
       pharmacy_id: phid
     };
     var med_data = this.http.post(
       this.baseUrl + 'Prescription_advise/getfullprice_detailslist',
       body
     );
     // console.log(med_data);
    return med_data;
  }
  getPresPriceUpdate(vrid) {
    var body = {
      visit_report_id: vrid
    };
    var rdata = this.http.post(
      this.baseUrl + "Prescription_advise/getprice_details",
      body
    );
    console.log(rdata);
    return rdata;
  }
  getLeadPrice(body) {
    return this.http.post(
      this.baseUrl + "Prescription_advise/getleadprice_details",
      body
    );
  }
  setPresQty(post) {
    return this.http.post(
      this.baseUrl + "Prescription_advise/setmedicine_issued",
      post
    );
  }
  setPresPrice(post) {
    return this.http.post(this.baseUrl + 'Prescription_advise/setprice_details', post);
  }
  setMedicinepricedetails(post) {
    return this.http.post(this.baseUrl + 'Prescription_advise/setmasterprice_details', post);
  }
  getLeads(post) {
    console.log('leadslist');
    console.log(post);
    return this.http.post(this.baseUrl + "Prescription_advise/getleads", post);
  }
  getOrders(post) {
    console.log('Orders');
    console.log(post);
    return this.http.post(this.baseUrl + "pharmacyorder/getorder_list", post);
  }
  getOrderDetails(oid, pid) {
    var body = {
      medicine_order_id: oid,
      pharmacy_id: pid
    };
    return this.http.post(
      this.baseUrl + "pharmacyorder/getorder_detailslist",
      body
    );
  }
  getOrderInvoiceDetails(post) {
    var body = {
      medicine_order_id: post
    };
    return this.http.post(
      this.baseUrl + "pharmacyorder/getPharmacy_invoice",
      body
    );
  }
  getPharmacy_profile(post) {
    return this.http.post(
      this.baseUrl + "Pharmacy_login/getPharmacy_profile",
      post
    );
  }
  setOrderStatus(post) {
    console.log(post);
    return this.http.post(
      this.baseUrl + "pharmacyorder/setorder_confirmation",
      post
    );
  }
  setOrderPacked(post) {
    return this.http.post(
      this.baseUrl + "pharmacyorder/setmedicine_confirmation",
      post
    );
  }
  setDidNotPurchase(post) {
    return this.http.post(
      this.baseUrl + "Prescription_advise/notpurchase",
      post
    );
  }
  setDateBill(post) {
    return this.http.post(
      this.baseUrl + "Prescription_advise/setreminder",
      post
    );
  }
  setConfirmPrice(post) {
    return this.http.post(
      this.baseUrl + "Prescription_advise/setprice_confirmed",
      post
    );
  }
  authCheck(post) {
    return this.http.post(
      this.baseUrl + "Pharmacy_login/Pharmacy_Userlogin",
      post
    );
  }
  getPackageMaster() {
    return this.http.post(this.baseUrl + "Prescription_advise/getPacking_masters", httpOptions);
    // return this.http.get("https://jsonplaceholder.typicode.com/todos/1");
  }
  getMedTypeMaster() {
    const postData = {
      medicine_type_id: 1
    };
    return this.http.post(this.baseUrl + "MedicineType/MedicineType", postData);
    // return this.http.get("https://jsonplaceholder.typicode.com/todos/1");
  }
  getMedicineMaster(data) {
    const postData = {
      medicine_name: data
    };
    return this.http.post(this.baseUrl + "PatientDashboard/Medicine_autucomplete", postData)
      .pipe(
        map((results: Array<any>) => {
          // return results.map(el => el.medicine_name).slice(0,15);
          return results.slice(0,15);
        })
      );
    // return this.http.get("https://jsonplaceholder.typicode.com/todos/1");
  }
  getGenericMaster(data) {
    const postData = {
      generic_name: data
    };
    return this.http.post(this.baseUrl + "CaseStudy/generic_master_autocmplt", postData).pipe(
      map((results: Array<any>) => {
        // return results.map(el => el.medicine_name).slice(0,15);
        return results.slice(0,15);
      })
    );
    // return this.http.get("https://jsonplaceholder.typicode.com/todos/1");
  }


  setInvoice(post) {
    return this.http.post(
      this.baseUrl + "pharmacyorder/setPharmacy_invoice",
      post
    );
  }
  getPlaces(post) {
    return this.http.post(
      this.baseUrl + "DoctorCreation/GetCountry",
      post
    );
  }

  setPharmacy_profile(post) {

    // Below postData should not be sent.
    // in the same formdata, stringified values can be sent.

    return this.http.post(this.baseUrl + "Pharmacy_login/setPharmacy_profile", post);
    // .subscribe((response)=> {
    //   console.log(response);
    // });

    // return this.http.post(
    //   this.baseUrl + "Pharmacy_login/setPharmacy_profile",
    //   post
    // );
  }

  // Digitize
  setImageStatus(data) {
    var post = {
      did_not_purchase : data.did_not_purchase,
      visit_report_id: data.visit_report_id
    };
    return this.http.post(
      this.baseUrl + "Prescription_advise/Setdigitize_imagestatus",
      post
    );
  }

  getDigiItems(vrid) {
    var post = {
      Dml_Indicator : 'S',
      visit_report_id: vrid
    };
    return this.http.post(
      this.baseUrl + "/PatientDashboard/Medicine_Prescription",
      post
    );
  }
  
  editDigiItems(postData) {
    return this.http.post(
      this.baseUrl + "/PatientDashboard/Medicine_Prescription",
      postData
    );
  }

  insertDigiItems(postData) {
    return this.http.post(
      this.baseUrl + "/PatientDashboard/Medicine_Prescription",
      postData
    );
  }
  
  updateDigiItems(postData) {
    return this.http.post(
      this.baseUrl + "/PatientDashboard/Medicine_Prescription",
      postData
    );
  }

  deleteDigiItems(postData) {
    
    return this.http.post(
      this.baseUrl + "/PatientDashboard/Medicine_Prescription",
      postData
    );
  }

  setOrderStatus_Dunzo(postData) {
    return this.http.post(
      this.baseUrl + "Dunzo/Order_Details",
      postData
    );
  }

  setOrderStatus_Dunzo_confirm(postData) {
    return this.http.post(
      this.baseUrl + "Dunzo/Order_Confirm",
      postData
    );
  }

  uploadfile(post) {
    console.log(post);
    return this.http.post(
      this.baseUrl + "Pharmacy_Medicine_File_Upload/upload",
      post
    );
  }

  // medicineinsert_csv(post) {
  //   console.log(post);
  //   return this.http.get(
  //     this.basepythonUrl + "MedicineInsert/"+post,
  //   );
  // }

  medicineinsert_csv(data) {
    //return this.http.get("http://97.74.238.189:50103/MedicineInsert/"+data, httpOptions);
    //return this.http.get("https://testalgorithm.ursugar.com/MedicineInsert/"+data, httpOptions);
    return this.http.get("https://algorithm.ursugar.com/MedicineInsert/"+data, httpOptions);
    //return this.http.get("https://algorithm.medwayhospitals.com/MedicineInsert/"+data, httpOptions);
    // return this.http.get("https://jsonplaceholder.typicode.com/todos/1");
  }

  
  medicine_file_details(post) {
    console.log(post);
    return this.http.post(
      this.baseUrl + "pharmacyorder/medicine_file_details",
      post
    );
  }
}
